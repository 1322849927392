export function printElement(htmlElement, getWindow = false, w) {
  return new Promise((resolve) => {
    const printFrame = document.createElement('iframe')
    printFrame.name = 'print-frame'
    printFrame.style.position = 'fixed'
    printFrame.style.top = '0'
    printFrame.style.left = '-9999px'
    printFrame.style.height = '100%'
    printFrame.style.width = '0'
    printFrame.style.overflow = 'hidden'
    printFrame.style['z-index'] = '2147483647'
    printFrame.style.tabIndex = '-1'
    document.body.appendChild(printFrame)

    // Iframe ready.
    printFrame.onload = function onLoad() {
      setTimeout(() => {
        if (getWindow) {
          return resolve(printFrame)
        }
        window.frames['print-frame'].focus()

        // Open printing window.
        window.frames['print-frame'].print()
        printFrame.remove()
        w && w.close()
      }, 0)
    }

    // Build printing document.
    const frameDoc = printFrame.contentWindow
    frameDoc.document.open()
    frameDoc.document.write(
      `<!DOCTYPE html><html><head><title>${document.title}</title>`
    )

    // Add styles.
    Array.prototype.forEach.call(
      document.querySelectorAll('style'),
      (styleEl) => {
        styleEl = styleEl.cloneNode(true)
        frameDoc.document.write(styleEl.outerHTML)
      }
    )

    // Add css links.
    let styleElements = document.querySelectorAll('link[rel=stylesheet]')

    Array.prototype.forEach.call(styleElements, (linkEl) => {
      const newLinkEl = document.createElement('link')
      newLinkEl.rel = linkEl.rel
      newLinkEl.href = linkEl.href
      newLinkEl.media = 'print'
      newLinkEl.type = 'text/css'
      newLinkEl.media = 'all'
      frameDoc.document.write(newLinkEl.outerHTML)
    })
    styleElements = null

    frameDoc.document.write(
      '<style type="text/css">@page { size: auto;  margin: 5mm; }</style></head><body>'
    )

    // Add editor contents.
    frameDoc.document.write(htmlElement.innerHTML)
    frameDoc.document.write('</div></body></html>')
    frameDoc.document.close()
  })
}
