import {
  // getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
// const __t = getRootTranslator()
const __tc = getRootPluaralTranslator()

export let DraftPublishedStatusOptions = () => [
  { text: __tc('draft'), key: 'draft', color: '#7b8fa5' },
  { text: __tc('published'), key: 'published', color: '#89c540' },
]

export const changeStages = () => [
  { text: __tc('submitted'), key: 'submitted' },
  { text: __tc('planning'), key: 'planning' },
  { text: __tc('approval'), key: 'approval' },
  { text: __tc('implementation'), key: 'implementation' },
  { text: __tc('review'), key: 'review' },
]

export const stageConstants = {
  REVIEW: 'review',
  PLANNING: 'planning',
  BUILD: 'build',
  TESTING: 'testing',
  DEPLOYMENT: 'deployment',
  APPROVAL: 'approval',
}

export const releaseStages = () => [
  { text: __tc('submitted'), key: 'submitted' },
  { text: __tc('planning'), key: 'planning' },
  { text: __tc('approval'), key: 'approval' },
  { text: __tc('build'), key: 'build' },
  { text: __tc('testing'), key: 'testing' },
  { text: __tc('deployment'), key: 'deployment' },
  { text: __tc('review'), key: 'review' },
]
