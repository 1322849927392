<template>
  <ScreenBlocker>
    <FlotoContentLoader
      :loading="preferenceLoading || determinedLoginType !== true"
    >
      <template v-slot:waiting>
        <MRow class="flex-1 mb-2">
          <MCol class="flex flex-col flex-1 items-center justify-center">
            <MIcon
              name="spinner-third"
              class="fa-spin text-primary"
              size="3x"
            />
          </MCol>
        </MRow>
      </template>
      <FlotoThemeComponent portal="technician-portal">
        <FlotoShortcutHandler
          v-if="!preferenceLoading && determinedLoginType === true"
        >
          <SocketListener>
            <ChatProvider @chat-config="isChatEnabled = $event.enabled">
              <ContactOpsProvider
                @contactOps-config="isContactOpsEnabled = $event.enabled"
              >
                <ChatSocketProvider socket-path="/chat-server">
                  <MLayout class="mainLayout">
                    <Header :pinned="pinned" @change="handleChangePinnedMenu" />
                    <MLayout class="relative contentContainer">
                      <NavBar :key="$route.fullPath" :pinned="pinned" />
                      <MLayout
                        class="mainLayoutContent"
                        :class="{ pinned: pinned }"
                      >
                        <FlotoLicenseChecker>
                          <SetupGuideProvider>
                            <FlotoScrollView>
                              <MLayoutContent
                                class="content-layout"
                                :class="{ 'pb-8': isChatEnabled }"
                              >
                                <slot />
                              </MLayoutContent>
                              <SetupHelpBubble />
                            </FlotoScrollView>
                          </SetupGuideProvider>
                        </FlotoLicenseChecker>
                      </MLayout>
                      <CollaborationContainer />
                      <OngoingCallContainer />
                    </MLayout>
                  </MLayout>
                </ChatSocketProvider>
              </ContactOpsProvider>
            </ChatProvider>
          </SocketListener>
        </FlotoShortcutHandler>
      </FlotoThemeComponent>
    </FlotoContentLoader>
  </ScreenBlocker>
</template>

<script>
import Bus from '@utils/emitter'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'
import NavBar from '@components/layout/navbar'
import Header from '@components/layout/header'
import {
  PreferenceComputed,
  PreferenceMethods,
} from '@state/modules/preference'
import { authComputed, authMethods } from '@state/modules/auth'
import { BrandingComputed } from '@state/modules/branding'
import SocketListener from '@components/socket-listener.vue'
import ChatSocketProvider from '@components/chat-socket-provider.vue'
import CollaborationContainer from '@components/chat/collaboration-container.vue'
import OngoingCallContainer from '@components/contactOps/ongoing-call-container'
import ChatProvider from '@components/chat/chat-provider.vue'
import ContactOpsProvider from '@components/contactOps/contactOps-provider'
import { SsoConfigActions, SsoConfigComputed } from '@state/modules/sso-config'
import SetupGuideProvider from '@components/setup-guide/setup-guide-provider.vue'
import SetupHelpBubble from '@components/setup-guide/setup-help-bubble.vue'
import ScreenBlocker from '@components/screen-blocker.vue'

export default {
  name: 'Layout',
  components: {
    NavBar,
    Header,
    SocketListener,
    CollaborationContainer,
    OngoingCallContainer,
    ChatProvider,
    ContactOpsProvider,
    ChatSocketProvider,
    SetupGuideProvider,
    SetupHelpBubble,
    ScreenBlocker,
  },
  data() {
    return {
      determinedLoginType: false,
      pinned: false,
      isChatEnabled: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    ...authComputed,
    ...BrandingComputed,
    ...SsoConfigComputed,
  },
  watch: {
    companyFavicon: {
      immediate: true,
      handler(newValue) {
        let link = document.querySelector("link[rel*='icon']")
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = newValue
        link = null
        // document.getElementsByTagName('head')[0].appendChild(link);
      },
    },
    $route(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleChangePinnedMenu(false)
      }
    },
  },
  created() {
    this.fetchSsoConfig()
    const logoutHandler = (user) => {
      if (user.loginSource === 'sso_login') {
        if (this.ssoConfig.idpLogoutUrl) {
          return (window.location.href = `${this.ssoConfig.idpLogoutUrl}`)
        } else if (this.ssoConfig.idpLoginUrl) {
          return (window.location.href = `${this.ssoConfig.idpLoginUrl}`)
        }
      }
      this.$router.replace(
        this.$modules.getModuleRoute('auth', 'login', {
          query: { redirectFrom: this.$route.fullPath },
        })
      )
    }

    Bus.$on('auth:logout', logoutHandler)

    this.$once('hook:beforeDestroy', () => {
      Bus.$off('auth:logout', logoutHandler)
    })

    const globallyLocaleLoadedHandler = () => {
      if (this.determinedLoginType) {
        dispatchActionForAllModules('init', {
          exclude: ['preference', 'auth', 'branding', 'license'],
        })
      }
    }
    Bus.$once('app:globallyLocaleLoaded', globallyLocaleLoadedHandler)
  },
  mounted() {
    if (this.isPortalLogin) {
      if (this.user.userType !== 'technician') {
        return this.$router.replace(
          this.$modules.getModuleRoute('support-portal')
        )
      }
    }
    if (this.user.userType !== 'technician') {
      return this.$router.replace(
        this.$modules.getModuleRoute('support-portal')
      )
    }
    this.changeLoginType('technician-portal')
    if (this.loggedIn) {
      this.fetchPreferences()
        .then(() => this.fetchTenantPrefrences())
        .then(() => {
          this.determinedLoginType = true
          // Automatically run the `init` action for every module,
          // if one exists.
          if (window.globallyLocaleLoaded) {
            dispatchActionForAllModules('init', {
              exclude: ['preference', 'auth', 'branding', 'license'],
            })
          }
        })
    }
  },
  methods: {
    ...PreferenceMethods,
    ...authMethods,
    ...SsoConfigActions,
    handleChangePinnedMenu($event) {
      requestAnimationFrame(() => {
        this.pinned = $event
      })
    },
  },
}
</script>

<style lang="less" scoped>
.mainLayout {
  height: 100vh;
}

.mainLayoutContent {
  padding: @layout-header-height 0 0 0;
  // background-color: var(--neutral-lightest) !important;
  &.pinned {
    padding-left: 50px;
  }
}

.contentContainer {
  min-height: calc(100vh - @layout-header-height);
}

.content-layout {
  min-height: calc(100vh - @layout-header-height);

  @apply pt-3 overflow-x-hidden flex flex-col min-w-0; //pt-6 pl-3 pr-3
}
</style>
